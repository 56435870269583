// SPDX-FileCopyrightText: 2024 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

import * as Sentry from "@sentry/react";

import { Roger, parsePromise } from "@fe/roger-core";
import * as N3 from "n3";

Sentry.init({
  dsn: "https://237b9f7c9f63274e365643d796d61d1b@errs.sub.uni-goettingen.de/12",
  environment: process.env.NODE_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/bdnroger\.bdn-roger\.de/],
});

const bdnSchema = `@prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>.
@prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>.
@prefix dcterms: <http://purl.org/dc/terms/>.
@prefix dc: <http://purl.org/dc/elements/1.1/>.
@prefix textgrid: <http://textgridrep.de/textgrid:>.
@prefix roger: <https://www.sub.uni-goettingen.de/roger/schema#>.
@prefix xsd: <http://www.w3.org/2001/XMLSchema#>.
@prefix sh: <http://www.w3.org/ns/shacl#>.
@prefix dash: <http://datashapes.org/dash#>.
@prefix bdn: <https://bdn-edition.de/terms/>.
@prefix bdnshapes: <https://bdn-edition.de/shapes/>.
@prefix gndo: <https://d-nb.info/standards/elementset/gnd#>.
@prefix owl: <http://www.w3.org/2002/07/owl#>.
@prefix bf: <http://id.loc.gov/ontologies/bibframe/>.
@prefix rdagr1: <http://rdvocab.info/Elements/>.
@prefix bibo: <http://purl.org/ontology/bibo/>.
@prefix rdau: <http://rdaregistry.info/Elements/u/>.

# NodeShapes

bdnshapes:PersonShape
    a sh:NodeShape ;
    rdfs:label "Person Shape" ;
    sh:name "Person" ;
    sh:targetClass bdn:Person ;
    roger:formNode true ;
    roger:exportFileNameProperty bdnshapes:preferredNameForThePersonShape; # must be a field that allows a single value only, currently a workaround in the implementation prevents failure in other cases
    roger:prefillUrlTemplate "/gnd/{}/about/lds" ;
    sh:property bdnshapes:commentShape ,
                bdnshapes:preferredNameForThePersonShape ,
                bdnshapes:variantNameForThePersonShape ,
                bdnshapes:professionOrOccupationShape ,
                bdnshapes:biographicalOrHistoricalInformationShape ,
                bdnshapes:dateOfBirthShape ,
                bdnshapes:dateOfDeathShape ,
                bdnshapes:placeOfActivityShape,
                bdnshapes:sameAsShape .

bdnshapes:InformationCarrierShape
    a sh:NodeShape ;
    rdfs:label "Informationsträger Shape" ;
    sh:targetClass bdn:InformationCarrier ;
    roger:formNode false ;
    sh:property bdnshapes:commentShape ,
                bdnshapes:titleShape ,
                bdnshapes:alternativeShape ,
                bdnshapes:isItemOfShape ,
                bdnshapes:sameAsShape .

bdnshapes:WorkShape
    a sh:NodeShape ;
    rdfs:label "Werk Shape" ;
    sh:targetClass bdn:Work ;
    roger:formNode true ;
    roger:exportFileNameProperty bdnshapes:titleShape;
    roger:prefillUrlTemplate "/b3k/{}?output=ttl" ;
    sh:property bdnshapes:commentShape ,
                bdnshapes:titleShape ,
                bdnshapes:alternativeShape ,
                bdnshapes:creatorShape ,
                bdnshapes:sameAsShape .

bdnshapes:ArticleShape
    a sh:NodeShape ;
    rdfs:label "Artikel Shape" ;
    sh:targetClass bdn:Article ;
    roger:formNode true ;
    roger:exportFileNameProperty bdnshapes:titleShape;
    roger:prefillUrlTemplate "/b3k/{}?output=ttl" ;
    sh:property bdnshapes:commentShape ,
                bdnshapes:titleShape ,
                bdnshapes:alternativeShape ,
                bdnshapes:publisherShape ,
                bdnshapes:instantiatesShape ,
                bdnshapes:providerStatementShape ,
                bdnshapes:issuedShape ,
                bdnshapes:extentShape ,
                bdnshapes:placeOfPublicationShape ,
                bdnshapes:p60083Shape ,
                bdnshapes:isPartOfShape ,
                bdnshapes:pagesShape ,
                bdnshapes:sameAsShape .

bdnshapes:BookShape
    a sh:NodeShape ;
    rdfs:label "Buch Shape" ;
    sh:targetClass bdn:Book ;
    roger:formNode true ;
    roger:exportFileNameProperty bdnshapes:titleShape;
    roger:prefillUrlTemplate "/b3k/{}?output=ttl" ;
    sh:property bdnshapes:commentShape ,
                bdnshapes:titleShape ,
                bdnshapes:alternativeShape ,
                bdnshapes:publisherShape ,
                bdnshapes:instantiatesShape ,
                bdnshapes:providerStatementShape ,
                bdnshapes:issuedShape ,
                bdnshapes:extentShape ,
                bdnshapes:placeOfPublicationShape ,
                bdnshapes:p60083Shape ,
                bdnshapes:editionShape ,
                bdnshapes:sameAsShape .

# PropertyShapes

bdnshapes:commentShape
    a sh:PropertyShape ;
    sh:path rdfs:comment ;
    sh:datatype xsd:string ;
    dash:editor dash:TextAreaEditor ;
    dash:singleLine false ;
    sh:name "Kommentar" ;
    sh:maxCount 1 ;
    sh:order "0"^^xsd:decimal .

bdnshapes:preferredNameForThePersonShape
    a sh:PropertyShape ;
    sh:path gndo:preferredNameForThePerson ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Name" ;
    sh:minCount 1 ;
    sh:maxCount 1 ;
    sh:description "Der bevorzugte Name der Person." ;
    sh:order "1"^^xsd:decimal .

bdnshapes:variantNameForThePersonShape
    a sh:PropertyShape ;
    sh:path gndo:variantNameForThePerson ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Namensvariante" ;
    sh:description "Ein alternativer Name für die Person." ;
    sh:order "2"^^xsd:decimal .

bdnshapes:professionOrOccupationShape
    a sh:PropertyShape ;
    sh:path gndo:professionOrOccupationAsLiteral ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Tätigkeitsfeld" ;
    sh:description "Eine Tätigkeit oder ein Tätigkeitsfeld der Person." ;
    sh:order "3"^^xsd:decimal .

bdnshapes:biographicalOrHistoricalInformationShape
    a sh:PropertyShape ;
    sh:path gndo:biographicalOrHistoricalInformation ;
    sh:datatype xsd:string ;
    dash:editor dash:TextAreaEditor ;
    dash:singleLine false ;
    sh:name "Biographische oder historische Information" ;
    sh:order "4"^^xsd:decimal .

bdnshapes:dateOfBirthShape
    a sh:PropertyShape ;
    sh:path gndo:dateOfBirth ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Geburtsdatum" ;
    sh:maxCount 1 ;
    sh:description "Das Geburtsdatum der Person." ;
    sh:order "5"^^xsd:decimal .

bdnshapes:dateOfDeathShape
    a sh:PropertyShape ;
    sh:path gndo:dateOfDeath ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Sterbedatum" ;
    sh:maxCount 1 ;
    sh:description "Das Sterbedatum der Person." ;
    sh:order "6"^^xsd:decimal .

bdnshapes:placeOfActivityShape
    a sh:PropertyShape ;
    sh:path gndo:placeOfActivity ;
    sh:nodeKind sh:IRI ;
    dash:editor dash:URIEditor ;
    sh:name "Wirkungsort" ;
    sh:description "Eine Wikungsstätte, die mit der Person verbunden ist." ;
    sh:order "7"^^xsd:decimal .

bdnshapes:sameAsShape
    a sh:PropertyShape ;
    sh:path owl:sameAs ;
    sh:nodeKind sh:IRI ;
    dash:editor dash:URIEditor ;
    sh:name "Verknüpfter Datensatz" ;
    sh:order "15"^^xsd:decimal .

bdnshapes:titleShape
    a sh:PropertyShape ;
    sh:path dc:title ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Titel" ;
    sh:minCount 1 ;
    sh:maxCount 1 ;
    sh:description "Die bevorzugte Bezeichnung." ;
    sh:order "1"^^xsd:decimal .

bdnshapes:alternativeShape
    a sh:PropertyShape ;
    sh:path dcterms:alternative ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Alternativer Titel" ;
    sh:description "Eine alternative Bezeichnung." ;
    sh:order "2"^^xsd:decimal .

bdnshapes:isItemOfShape
    a sh:PropertyShape ;
    sh:path bdn:isItemOf ;
    sh:class bdn:Instance ;
    sh:node bdnshapes:ArticleShape ;
    sh:node bdnshapes:BookShape ;
    dash:editor dash:AutoCompleteEditor ;
    sh:nodeKind sh:IRI ;
    sh:maxCount 1 ;
    sh:name "Verknüpfte Werkinstanz" ;
    sh:description "Verknüpfung zu einer Ausgabe eines Werks" ;
    sh:order "3"^^xsd:decimal .

bdnshapes:creatorShape
    a sh:PropertyShape ;
    sh:path dcterms:creator ;
    sh:class bdn:Person ;
    sh:node bdnshapes:PersonShape ;
    dash:editor dash:AutoCompleteEditor ;
    sh:nodeKind sh:IRI ;
    sh:maxCount 1 ;
    sh:name "Autor" ;
    sh:description "Ein Autor des Werks." ;
    sh:order "3"^^xsd:decimal .

bdnshapes:publisherShape
    a sh:PropertyShape ;
    sh:path dcterms:publisher ;
    sh:class bdn:Person ;
    sh:node bdnshapes:PersonShape ;
    dash:editor dash:AutoCompleteEditor ;
    sh:nodeKind sh:IRI ;
    sh:name "Herausgeber" ;
    sh:description "Ein Herausgeber der Ausgabe." ;
    sh:order "10"^^xsd:decimal .

bdnshapes:instantiatesShape
    a sh:PropertyShape ;
    sh:path bdn:instantiates ;
    sh:class bdn:Work ;
    sh:node bdnshapes:WorkShape ;
    dash:editor dash:AutoCompleteEditor ;
    sh:nodeKind sh:IRI ;
    sh:maxCount 1 ;
    sh:name "Verknüpftes Werk" ;
    sh:description "Das zu einer Ausgabe zugehörige Werk." ;
    sh:order "11"^^xsd:decimal .

bdnshapes:providerStatementShape
    a sh:PropertyShape ;
    sh:path rdagr1:publicationStatement ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Erscheinungsvermerk" ;
    sh:maxCount 1 ;
    sh:description "Ein ausgabenspezifischer Erscheinungvermerk" ;
    sh:order "4"^^xsd:decimal .

bdnshapes:issuedShape
    a sh:PropertyShape ;
    sh:path dcterms:issued ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Veröffentlichungsdatum" ;
    sh:maxCount 1 ;
    sh:description "Veröffentlichungsdatum..." ;
    sh:order "5"^^xsd:decimal .

bdnshapes:extentShape
    a sh:PropertyShape ;
    sh:path dcterms:extent ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Kollationsvermerk" ;
    sh:maxCount 1 ;
    sh:description "Vermerk über den Umfang einer Ausgabe." ;
    sh:order "6"^^xsd:decimal .

bdnshapes:editionShape
    a sh:PropertyShape ;
    sh:path bibo:edition ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Edition" ;
    sh:maxCount 1 ;
    sh:description "Edition." ;
    sh:order "8"^^xsd:decimal .

bdnshapes:placeOfPublicationShape
    a sh:PropertyShape ;
    sh:path rdagr1:placeOfPublication ;
    sh:nodeKind sh:IRI ;
    dash:editor dash:TextFieldEditor ;
    sh:name "Erscheinungsort" ;
    sh:maxCount 1 ;
    sh:description "Angabe zum Erscheinungsort einer Ausgabe." ;
    sh:order "7"^^xsd:decimal .

bdnshapes:p60083Shape
    a sh:PropertyShape ;
    sh:path rdau:p60083 ;
    sh:nodeKind sh:IRI ;
    dash:editor dash:URIEditor ;
    sh:name "Digitalisat" ;
    sh:description "Link auf ein Digitalisat der entsprechenden Ausgabe." ;
    sh:order "9"^^xsd:decimal .

bdnshapes:pagesShape
    a sh:PropertyShape ;
    sh:path bibo:pages ;
    sh:datatype xsd:string ;
    dash:editor dash:TextFieldEditor ;
    dash:singleLine true ;
    sh:name "Seiten" ;
    sh:maxCount 1 ;
    sh:description "Seitenbereich des Artikels." ;
    sh:order "12"^^xsd:decimal .

bdnshapes:isPartOfShape
    a sh:PropertyShape ;
    sh:path bdn:isPartOf ;
    sh:class bdn:Book ;
    sh:node bdnshapes:BookShape ;
    dash:editor dash:AutoCompleteEditor ;
    sh:nodeKind sh:IRI ;
    sh:maxCount 1 ;
    sh:name "Bestandteil von" ;
    sh:description "Verknüpfung mit einer Publikation (z.B. Zeitschrift, Sammelband o.Ä.), in der der Artikel veröffentlicht wurde." ;
    sh:order "13"^^xsd:decimal .`;

const parse = async (input) => {
  const store = new N3.Store();
  const parser = new N3.Parser();
  return await parsePromise(parser, input, store);
};

function App({ schema }) {


  const [schemaStore, setSchemaStore] = React.useState();
  useEffect(() => {
    (async () => {
      let result;
      try {
        result = await parse(schema);
      } catch (error) {
        console.log(error.message);
      }
      if (result) setSchemaStore(result);
    })();
  }, [schema]);

  return <Roger schema={schemaStore} />;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App schema={bdnSchema} />
  </React.StrictMode>
);
